import React from "react";
import { Routes, Route,  useLocation, useNavigationType, } from "react-router-dom";
import { useEffect } from "react";
import IndexPage from "./pages/Index.js";
import LoginPage from "./pages/Login.js";
import ContactPage from "./pages/Contact.js";
import RegisterPage from "./pages/Register.js";
import _404Page from "./pages/404.js";
import Navbar from "./components/Navbar.jsx";
import Footer from "./components/Footer.jsx";
import Courses from "./pages/Courses.js";
import Course from "./pages/Course";
import _ComingSoon from './pages/ComingSoon.js';

function App() {
    const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);
  return (
    <>
      {/* <Navbar /> */}
      <Routes>
      
         <Route path="*" element={<_ComingSoon />} />

      </Routes>
      {/* <Footer /> */}
    </>
  );
}

export default App;
