import React, { useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import supabase from '../createClient';

export default function ComingSoon() {
  const [email, setEmail] = useState('');

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleNotifyMe = async () => {
    if (email.trim() === '') {
      alert('Email field cannot be empty');
      return;
    }

    if (!validateEmail(email)) {
      alert('Please enter a valid email address');
      return;
    }

    try {
      const { data, error } = await supabase
        .from('early_birds')
        .insert([{ email }]);

      if (error) {
        throw error;
      }

      alert("Thank you for subscribing! You'll be one of the first to know when we go live.");
      setEmail('');
    } catch (error) {
      console.error('Error inserting email:', error.message);
      alert('Oops! Looks like we already have your email. We will notify you when we go live.');
    }
  };

  return (
    <HelmetProvider>
      <section className='relative py-12 md:py-24 bg-white-50'>
        <div className='container mx-auto px-4'>
          <div className='flex items-center justify-center mb-12'>
            <img
              className='h-20 w-auto'
              src='/images/1xSkills-Logo-png-1709229383002.webp'
              alt='1xSkills'
            />
          </div>
          <div className='relative z-10 px-12 py-16 bg-white border border-black rounded-4xl'>
            <div className='flex flex-wrap -m-4 divide-y md:divide-y-0 md:divide-x divide-neutral-50'>
              <div className='w-full md:w-2/3 p-4 pb-12'>
                <h3 className='relative mb-12 text-5xl sm:text-6xl md:text-9xl xl:text-11xl text-neutral-800 font-semibold font-heading'>
                  <span className='relative inline-block'>
                    <span className='relative z-10'>Coming Soon</span>
                    <span className='absolute -bottom-2 left-0 h-2.5 w-full bg-green-200 rounded-full' />
                  </span>
                </h3>
                <p className='text-2xl text-neutral-600 font-semibold tracking-tight'>
                  Shhh..... Great things take time. We are working on something amazing. Stay tuned!
                </p>
              </div>
              <div className='w-full md:w-1/3 p-4'>
                <div className='py-12 md:py-0'>
                  <div className='max-w-max mx-auto'>
                    <img
                      className='h-auto w-auto'
                      src='https://qdsobxwxlzryslxrlvgt.supabase.co/storage/v1/object/sign/Meme/giphy%20(1)%20(1).gif?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJNZW1lL2dpcGh5ICgxKSAoMSkuZ2lmIiwiaWF0IjoxNzMyODg2MTQzLCJleHAiOjE3NjQ0MjIxNDN9.kyUe_xufgSJqpmuutRy22UgKeixNWBCV97YlVhmLibU&t=2024-11-29T13%3A15%3A43.506Z'
                      alt='Coming Soon'
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='grid grid-cols-1 gap-3 items-center w-full xs:grid-cols-1 md:grid-cols-2 md:w-auto'>
              <input
                type='email'
                placeholder='Enter your email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className='p-4 text-black rounded-lg border border-gray-300 focus:ring-4 focus:ring-neutral-400 transition duration-200 mb-3 md:mb-0'
              />
              <button
                onClick={handleNotifyMe}
                className='w-full md:w-40 mb-3 md:mb-0 inline-flex justify-center items-center text-center p-4 font-semibold tracking-tight text-2xl text-white bg-neutral-900 hover:bg-neutral-800 focus:bg-neutral-800 rounded-lg focus:ring-4 focus:ring-neutral-400 transition duration-200'
              >
                Notify me
              </button>
            </div>
          </div>
          <div className='flex items-center justify-center mt-24'>
            <p className='text-sm text-neutral-500'>
              &copy; 2024 1xSkills from 1 Percent Verse Private Limited.
            </p>
          </div>
        </div>
      </section>
    </HelmetProvider>
  );
}